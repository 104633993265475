// Import SalesforceSans-Bold Font
@font-face {
    font-family: "SalesforceSans-Bold";
    src: local("SalesforceSans-Bold"), url(./fonts/SalesforceSans-Bold.woff) format("woff");
    -webkit-filter: blur(0.000001px);
    -webkit-font-smoothing: antialiased;
}

// Import SalesforceSans-BoldItalic Font
@font-face {
    font-family: "SalesforceSans-BoldItalic";
    src: local("SalesforceSans-BoldItalic"), url(./fonts/SalesforceSans-BoldItalic.woff) format("woff");
}

// Import SalesforceSans-Book Font
@font-face {
    font-family: "SalesforceSans-Book";
    src: local("SalesforceSans-Book"), url(./fonts/SalesforceSans-Book.woff) format("woff");
}

// Import SalesforceSans-Italic Font
@font-face {
    font-family: "SalesforceSans-Italic";
    src: local("SalesforceSans-Italic"), url(./fonts/SalesforceSans-Italic.woff) format("woff");
}

// Import SalesforceSans-Light Font
@font-face {
    font-family: "SalesforceSans-Light";
    src: local("SalesforceSans-Light"), url(./fonts/SalesforceSans-Light.woff) format("woff");
}

// Import SalesforceSans-LightItalic Font
@font-face {
    font-family: "SalesforceSans-LightItalic";
    src: local("SalesforceSans-LightItalic"), url(./fonts/SalesforceSans-LightItalic.woff) format("woff");
}

// Import SalesforceSans-Regular Font
@font-face {
    font-family: "SalesforceSans-Regular";
    src: local("SalesforceSans-Regular"), url(./fonts/SalesforceSans-Regular.woff) format("woff");
    -webkit-filter: blur(0.000001px);
    -webkit-font-smoothing: antialiased;
}

// Import SalesforceSans-Semibold Font
@font-face {
    font-family: "SalesforceSans-Semibold";
    src: local("SalesforceSans-Semibold"), url(./fonts/SalesforceSans-Semibold.woff) format("woff");
}

// Import SalesforceSans-Thin Font
@font-face {
    font-family: "SalesforceSans-Thin";
    src: local("SalesforceSans-Thin"), url(./fonts/SalesforceSans-Thin.woff) format("woff");
}

// Import SalesforceSans-ThinItalic Font
@font-face {
    font-family: "SalesforceSans-ThinItalic";
    src: local("SalesforceSans-ThinItalic"), url(./fonts/SalesforceSans-ThinItalic.woff) format("woff");
}

//Set Variable Overrides
$font-family-base: "SalesforceSans-Regular";

$dark-blue: #215ca0;
$light-gray: #f2f2f3;
$salesforce-blue: #00a1e0;
$transparent: rgba(255, 255, 255, 0);
$white-opaque: rgba(255, 255, 255, 0.5);
$input-placeholder-color: white !important;
$desert-sand: #ffe19f;

$theme-colors: (
    "primary": #0070d2,
    "secondary": #6c757d,
    "success": #198754,
    "info": #0dcaf0,
    "warning": #ffc107,
    "danger": #dc3545,
    "light": #f8f9fa,
    "dark": #212529,
);

// Custom color map
$custom-colors: (
    "salesforce-blue": $salesforce-blue,
    "dark-blue": $dark-blue,
    "light-gray": $light-gray,
    "transparent": $transparent,
    "white-opaque": $white-opaque,
    "desert-sand": $desert-sand,
);

//Custom validation colors
$form-feedback-icon-valid-color: $salesforce-blue;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:  #dc3545;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
$form-validation-states: (
    "valid": (
        "color": $form-feedback-icon-valid-color,
        "icon": $form-feedback-icon-valid,
    ),
    "invalid": (
        "color": #dc3545,
        "icon": $form-feedback-icon-invalid
    )
);

//Merge the two colors
$theme-colors: map-merge($theme-colors, $custom-colors);

html,
body,
#root {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.SalesforceSans-Light {
    font-family: "SalesforceSans-Light" !important;
}

.SalesforceSans-Bold {
    font-family: "SalesforceSans-Bold" !important;
}

.airplane-wrapper {
    position: absolute;
    //XXL
    @media screen and (min-width: 1400px) {
        height: calc(869px / 4);
        width: calc(1709px / 4);
        left: -20px;
        top: -140px;
    }
    //XL
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        height: calc(869px / 4.5);
        width: calc(1709px / 4.5);
        left: 0px;
        top: -135px;
    }
    //LG
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        height: calc(869px / 5.5);
        width: calc(1709px / 5.5);
        left: -20px;
        top: -100px;
    }
    & .airplane {
        image-rendering: -webkit-optimize-contrast;
        transform: translateZ(0);
        height: 100%;
        width: 100%;
    }
}

.contrast-btn{
    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
}

.contrast-image{
    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
}

.survey-card {
    cursor: pointer;
    background-color: $transparent !important;
    &.active-card {
        background-color: $white-opaque !important;
    }
}

.btn-white:hover {
    background-color: #f0f0f0 !important;
}

.column-wrapper {
    background-color: $light-gray;
}

nav {
    height: 80px;
    border-bottom: 3px solid #0070d2;
    background-color: #fff;
}

.wrapper {
    max-width: 1400px !important;
}

.main-loader {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    height: 300px;
    width: 20rem; /* Need a specific value to work */
}

.main-loader-text {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    height: 300px;
    width: 20rem; /* Need a specific value to work */
}

.bg-blue-gradient {
    background: rgb(66, 163, 212);
    background: linear-gradient(
        0deg,
        rgba(66, 163, 212, 1) 40%,
        rgba(70, 177, 221, 1) 41%,
        rgba(0, 20, 84, 0.8979697943409172) 85%
    );
}

.assessment-step {
    min-height: 500px;
    height: 100%;
}

.step-hidden {
    opacity: 0;
}

.step-container {
    will-change: transform;
    transition: all 3s ease;
}

.form-input-pill {
    border-radius: 1rem !important;
    background-color: $transparent !important;
    color: white !important;
    $input-placeholder-color: white !important;
}

//Swap between 1 & 3 item carousels
@media screen and (max-width: 768px) {
    #lg-carousel {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    #sm-carousel {
        display: none !important;
    }
}

.sand-wave{
    background-image: url('../assets/BG_Asset\ 4@3x.png');
    background-size: 100% 100%;
    @media screen and (max-width: 576px) {
        background-image: url('../assets/BG_Asset 4-v2(500x500).png') !important;
    }
}

.white-wave{
    background-image: url('../assets/BG_Asset\ 5@3x.png');
    background-size: 100% 100%;
}

.forest{
    background-image: url('../assets/BG_Asset\ 6@3x.png');
    background-size: 100% 100%;
    @media screen and (max-width: 576px) {
        padding-top: 200px;
        background-image: url('../assets/BG_Asset 5-6-v2(500x500)(1).png') !important;
        transform: translateX(0px);
    }
}

.carousel-column {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    will-change: transform;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    transform: translateZ(0);
    & p {
        transition: all 1.2s;
    }
    & h1 {
        transition: all 1.2s;
    }
}

.BrainhubCarouselItem:not(.BrainhubCarouselItem--active) {
    will-change: transform;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    opacity: 0.7;
    transform: translateZ(0);
    & p {
        font-size: 14px;
    }
    & h1 {
        font-size: 40px;
    }
    & #middleCol {
        max-width: 80% !important;
    }
    @media screen and (min-width: 1400px) {
        & p {
            font-size: 17px;
        }
        & h1 {
            font-size: 45px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
        & p {
            font-size: 12px;
        }
        & h1 {
            font-size: 35px;
        }
    }
}

.BrainhubCarouselItem--active {
    will-change: transform;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    transition: all 0.3s ease;
    opacity: 1 !important;
    //xxl
    @media screen and (min-width: 1400px) {
        & p {
            font-size: 23px;
        }
        & h1 {
            font-size: 65px;
        }
    }
    //xl
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        & p {
            font-size: 21px;
        }
        & h1 {
            font-size: 55px;
        }
    }
    //lg
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        & p {
            font-size: 19px;
        }
        & h1 {
            font-size: 53px;
        }
    }
    //md
    @media screen and (min-width: 768px) and (max-width: 992px) {
        & p {
            font-size: 17px;
        }
        & h1 {
            font-size: 45px;
        }
    }

    @media screen and (max-width: 768px) {
        & p {
            font-size: 16px;
        }
        & h1 {
            font-size: 45px;
        }
    }
}

.BrainhubCarousel__container, .BrainhubCarousel {
    //xxl
    @media screen and (min-width: 1400px) {
        min-height: 450px;
    }
    //xl
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        min-height: 450px;
    }
    //lg
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        min-height: 400px;
    }
    //md
    @media screen and (min-width: 768px) and (max-width: 992px) {
        min-height: 300px;
    }
    //sm
    @media screen and (min-width: 576px) and (max-width: 768px) {
        min-height: 275px;
    }
    //xs
       @media screen and (max-width: 576px) {
        min-height: 275px;
    }
   
   
      
}

.BrainhubCarousel__arrows{
    background-color: $salesforce-blue !important
}

.waving-wrapper {
    position: absolute;
    //XXXL
    @media screen and (min-width: 1800px) {
        height: calc(842px/1.2);
        width: calc(596px/1.2);
        right: -15vw;
        top: -600px;
    }   
    //XXL
    @media screen and (min-width: 1400px) and (max-width: 1800px) {
        height: calc(842px/1.5);
        width: calc(596px/1.5);
        right: -20px;
        top: -490px;
    }
    //XL
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        height: calc(842px/1.8);
        width: calc(596px/1.8);
        right: 120px;
        top: -420px
    }
    //LG
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        height: calc(842px/1.8);
        width: calc(596px/1.8);
        right: 130px;
        top: -460px
    }
    //MD
    @media screen and (min-width: 768px) and (max-width: 992px) {
        height: calc(842px/2.2);
        width: calc(596px/2.2);
        right: 60px;
        top: -400px
    }
    //sm
    @media screen and (max-width: 768px) {
        height: calc(842px/2.6);
        width: calc(596px/2.6);
        right: 3vw;
        top: -310px
    }
    //xs
    @media screen and (max-width: 576px) {
        height: calc(842px/3);
        width: calc(596px/3);
        right: 3vw;
        top: -270px
    }

    & .waving {
        image-rendering: -webkit-optimize-contrast;
        transform: translateZ(0);
        height: 100%;
        width: 100%;
    }
}

.experience-card {
    border: 20px solid;
    border-image-source: url("../assets/5_Experience_BG_Wide_Shadow.png");
    //top right bottom left
    border-image-slice: 0 0 0 0 fill;
    border-image-width: 0px;
    z-index: 10;
    //xxl
    @media screen and (min-width: 1400px) {
        transform: translateY(-40px);
    }
    //xl
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        transform: translateY(-60px);
    }
    //lg
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        transform: translateY(-100px);
    }
    //md
    @media screen and (min-width: 768px) and (max-width: 992px) {
        border-image-source: url("../assets/5_Experience_BG_Narrow_Shadow.png");
        transform: translateY(-95px);
    }
    //sm
    @media screen and (min-width: 576px) and (max-width: 768px) {
        border-image-source: url("../assets/5_Experience_BG_Narrow_Shadow.png");
        transform: translateY(-60px);
    }
    //xs
    @media screen and (max-width: 576px) {
        border-image-source: url("../assets/5_Experience_BG_Narrow_Shadow.png");
        transform: translateY(-60px);
        padding-bottom: 80px;
    }
}

.custom-bullets {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

.custom-bullets li{
    @media screen and (max-width: 576px) {
        font-size: 1rem;
    }
}

.custom-bullets li::before {
    content: "● ";
    color: #2c92c5;
    padding-right: 5px;
    font-size: 2rem;
    line-height: 1.5rem;
    position: absolute;
    left: -25px;
    @media screen and (max-width: 576px) {
        font-size: 1rem;
    }
}

.pointing-wrapper{
    position: absolute;
    //XXL
    @media screen and (min-width:1400px) {
        height: calc(596px/1.6);
        width: calc(842px/1.6);
        left: -10px;
        top: -260px;
    }
    //XL
    @media screen and (min-width:1200px) and (max-width: 1400px) {
        height: calc(596px/2);
        width: calc(842px/2);
        left: 0px;
        top: -180px;
    }
    //LG
    @media screen and (min-width:992px) and (max-width: 1200px) {
        height: calc(596px/2);
        width: calc(842px/2);
        left: 0px;
        top: -160px;
    }
    //MD
    @media screen and (min-width:768px) and (max-width: 992px) {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        height: calc(596px/2.2);
        width: calc(842px/2.2);
        right: -20px;
        top: -150px;
    }
    //sm
    @media screen and (max-width: 768px) {
        display: none;
    }

    & .pointing{
        height: 100%;
        width: 100%;
    }
}

.polsource-card{
    @media screen and (min-width:1400px) {
        transform: translateY(-80px)
    }
    //XL
    
    @media screen and (min-width:992px) and (max-width: 1400px) {
        transform: translateY(-80px);
        margin-bottom: 50px;
    }
    //MD
    @media screen and (min-width:768px) and (max-width: 992px) {
        transform: translateY(-20px);
        margin-bottom: 50px;
    }
}

.campfire{
    max-width: 700px;
}

.btn:hover{
    transform: scale(1.05);
    transition: all linear 0.1s;
}

a:hover{
    opacity: 0.9;
    transition: all linear 0.1s;
}



//Import bootstrap to set overriden variables
@import "../../node_modules/bootstrap/scss/bootstrap";
